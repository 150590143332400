<template>
    <div class="login-page">
      <form class="login__form" @submit.prevent="login">
        <div class="login__form-item">
          <ip-text-input
            name="email"
            id="email"
            placeholder="Введите имейл"
            label="Email"
            v-model="email"
            v-validate="{required: true}"
            :invalid="errors.has('email') || isInvalid">
          </ip-text-input>
          <div
            v-if="errors.has('email')"
            class="error-message">
            Это поле обязательно
          </div>
        </div>
        <div class="login__form-item">
          <ip-text-input
            name="password"
            type="password"
            id="password"
            placeholder="Введите пароль"
            label="Пароль"
            v-model="password"
            v-validate="{required: true}"
            :invalid="errors.has('password') || isInvalid">
          </ip-text-input>
          <div
            v-if="errors.has('password')"
            class="error-message">
            Это поле обязательно
          </div>
          <div
            v-if="isInvalid"
            class="error-message">
            Неверный пароль или email
          </div>
        </div>
        <ip-button type="submit" size="big"> Подтвердить </ip-button>
      </form>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import IpTextInput from "../components/IpTextInput";
import IpButton from "../components/IpButton";
import apiRoutes from "../consts/apiRoutes";

export default {
  name: "login",

  components: {
    IpTextInput,
    IpButton
  },

  data() {
    return {
      email: '',
      password: '',
      isInvalid: false
    };
  },

  methods: {
    ...mapActions([
      'fetchCurrentUser'
    ]),

    async login() {
      this.isInvalid = false;

      const isValid = await this.$validator.validate();

      if (isValid) {
        try {
          const result = await this.axios.post(apiRoutes.login, { email: this.email, password: this.password });
          this.$localStorage.set('rentPulseBackofficeToken', result.data.token);
          this.axios.defaults.headers.common['x-access-token'] =  result.data.token;
          this.fetchCurrentUser();
          this.$router.push({ name: 'home' });
        } catch (error) {
          console.warn(error);
          this.isInvalid = true;
        }
        console.log('login');
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.login{
  &-page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__form{
    padding: 24px;
    max-width: 320px;
    width: 100%;
    &-item{
      margin-bottom: 8px;
    }
    ::v-deep .ip-button{
      width: 100%;
    }
  }
}
</style>