<template>
  <button
    :class="[
      'ip-button',
      size,
      theme,
      color
    ]"
    @click="$emit('click')"
    :disabled="disabled"
    :type="type"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "medium"
    },
    type: {
      type: String,
      default: ""
    },
    theme: {
      type: String,
      default: "normal"
    },
    color: {
      type: String,
      default: "black"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
.ip-button{
  outline: none !important;
  border: none;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: none;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  color: $white;
  font-size: 12px;
  min-width: 100px;
  &:hover{
    cursor: pointer;
  }
  &[disabled]{
    cursor: not-allowed !important;
    background-color: $lightGray !important;
    border-color: $lightGray !important;
    color: $white !important;
  }
  &.big{
    font-weight: 600;
    line-height: 12px;
    padding: 16px;
    min-width: 200px;
  }
  &.rounded{
    border-radius: 17px;
    font-weight: 500;
    text-transform: none;
  }
  &.rounded-outline{
    box-sizing: border-box;
    border: solid 3px transparent;
    border-radius: 17px;
    font-weight: 500;
    text-transform: none;
    padding: 4px;
    height: 30px;
  }
  &.black{
    background: $mirage;
    &.rounded-outline{
      background: transparent;
      border-color: $mirage;
      color: $mirage;
      &:hover{
        border-color: $oxfordBlue;
      }
    }
    &:hover{
      color: $white;
      background: $oxfordBlue;
    }
    &[disabled]{
      background: $lightGray !important;
      color: $white !important;
    }
  }
  &.white{
    background: $white;
    color: $black;
    &.rounded-outline{
      background: transparent;
      border-color: $white;
      color: $white;
      &:hover{
        border-color: $cobalt;
      }
    }
    &:hover{
      background: $cobalt;
      color:$white;
    }
    &[disabled]{
      background: $white !important;
      color: $lightGray !important;
    }
  }
  &.blue{
    background: $scienceBlue;
    &.rounded-outline{
      background: transparent;
      border-color: $scienceBlue;
      color: $scienceBlue;
      &:hover{
        border-color: $cobalt;
      }
    }
    &:hover{
      background: $cobalt;
      color:$white;
    }
  }
  &.red{
    background: $red;
    &.rounded-outline{
      background: transparent;
      border-color: $red;
      color: $red;
      &:hover{
        border-color: $persianRed;
        color:$white;
      }
    }
    &:hover{
      background: $persianRed;
    }
  }
}
</style>
